import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49a008d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-xs-12 col-sm-12 col-md-8 q-pa-xs" }
const _hoisted_2 = {
  key: 0,
  class: "text-h6 q-pl-md q-pt-md"
}
const _hoisted_3 = {
  key: 1,
  class: "q-pl-md"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "col-xs-12 col-sm-12 col-md-4 q-pa-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DuvidaForm = _resolveComponent("DuvidaForm")!
  const _component_q_video = _resolveComponent("q-video")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_chat_message = _resolveComponent("q-chat-message")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DuvidaForm, { ref: "modalDuvidaForm" }, null, 512),
    (_ctx.disciplina)
      ? (_openBlock(), _createBlock(_component_q_page, {
          key: 0,
          class: "row"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_q_video, {
                ratio: 16 / 9,
                src: 
          _ctx.aulaSelecionada
            ? _ctx.aulaSelecionada.url
            : _ctx.alterarAula(_ctx.disciplina.assuntos[0].aulas[0])
        
              }, null, 8, ["src"]),
              (_ctx.aulaSelecionada)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.aulaSelecionada.descricao), 1))
                : _createCommentVNode("", true),
              (_ctx.aulaSelecionada)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_ctx.aulaSelecionada.aula_assistida)
                      ? (_openBlock(), _createBlock(_component_q_chip, {
                          key: 0,
                          clickable: "",
                          color: "green",
                          "text-color": "white",
                          icon: "visibility",
                          onClick: _ctx.marcarAula
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_tooltip, { class: "bg-red" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Clique para marcar como não assitida ")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(" Aula Assistida ")
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : (_openBlock(), _createBlock(_component_q_chip, {
                          key: 1,
                          clickable: "",
                          color: "red",
                          "text-color": "white",
                          icon: "visibility_off",
                          onClick: _ctx.marcarAula
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_tooltip, { class: "bg-green" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Clique para marcar como assitida ")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(" Aula Não Assistida ")
                          ]),
                          _: 1
                        }, 8, ["onClick"])),
                    _createVNode(_component_q_chip, {
                      clickable: "",
                      color: "primary",
                      "text-color": "white",
                      icon: "help",
                      onClick: _ctx.criarDuvida
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, { class: "bg-primary" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Clique se tiver alguma dúvida sobre essa aula ")
                          ]),
                          _: 1
                        }),
                        _createTextVNode(" Criar Dúvida ")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_q_separator, {
                inset: "",
                class: "q-my-md"
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aulaSelecionada.duvidas, (duvida, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  style: {"margin-left":"20px"},
                  key: i
                }, [
                  _createVNode(_component_q_chat_message, {
                    "text-color": "white",
                    text: ['<strong>' + duvida.titulo + '</strong><br>' + duvida.texto],
                    "text-html": "",
                    name: duvida.aluno.nome,
                    stamp: duvida.created_at,
                    avatar: 
            duvida.aluno?.imagem
              ? `${_ctx.urlImg}${duvida.aluno?.imagem}`
              : require('../../../assets/aluno-avatar.png')
          ,
                    "bg-color": "primary"
                  }, null, 8, ["text", "name", "stamp", "avatar"]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(duvida.comentarios, (comentario) => {
                    return (_openBlock(), _createBlock(_component_q_chat_message, {
                      key: comentario.id,
                      "text-color": "white",
                      name: comentario.user.nome,
                      text: [comentario.texto],
                      stamp: comentario.created_at,
                      sent: comentario.user.perfil.nome == 'professor' || comentario.user.perfil.nome == 'administrador',
                      avatar: 
            comentario.user.imagem
              ? `${_ctx.urlImg}${comentario.user?.imagem}`
              : require('../../../assets/aluno-avatar.png')
          ,
                      "bg-color": 
            comentario.user.perfil.nome == 'professor' || comentario.user.perfil.nome == 'administrador' ? 'green' : 'primary'
          
                    }, null, 8, ["name", "text", "stamp", "sent", "avatar", "bg-color"]))
                  }), 128))
                ]))
              }), 128)),
              (_ctx.aulaSelecionada)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "q-pa-md",
                    innerHTML: _ctx.aulaSelecionada.resumo
                  }, null, 8, _hoisted_4))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_q_list, {
                bordered: "",
                separator: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.disciplina.assuntos, (assunto) => {
                    return (_openBlock(), _createBlock(_component_q_expansion_item, {
                      "expand-separator": "",
                      group: "assuntos",
                      icon: "style",
                      "header-class": "bg-grey-3",
                      caption: assunto.aulas.length + ' aulas',
                      label: assunto.descricao,
                      key: assunto.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_list, {
                          bordered: "",
                          separator: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assunto.anexos, (anexo) => {
                              return (_openBlock(), _createBlock(_component_q_item, {
                                key: anexo.id,
                                class: "item-aula",
                                clickable: "",
                                tag: "a",
                                href: `${_ctx.url}${anexo.anexo}`,
                                target: "_blank"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_item_section, { avatar: "" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_icon, {
                                        size: "17px",
                                        name: "attach_file",
                                        color: "primary"
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_q_item_section, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(anexo.descricao), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["href"]))
                            }), 128)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assunto.aulas, (aula) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: aula.id
                              }, [
                                (
                  _ctx.store.getters.userPerfil != 'aluno' ||
                  new Date().getTime() > new Date(aula.data_exibicao).getTime()
                )
                                  ? (_openBlock(), _createBlock(_component_q_item, {
                                      key: 0,
                                      class: "item-aula",
                                      clickable: "",
                                      onClick: ($event: any) => (_ctx.alterarAula(aula))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_item_section, { avatar: "" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_q_icon, {
                                              size: "17px",
                                              name: "play_circle",
                                              color: aula.aula_assistida ? 'green' : 'red'
                                            }, null, 8, ["color"])
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_q_item_section, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(aula.descricao), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(aula.anexos, (anexo) => {
                                  return (_openBlock(), _createBlock(_component_q_item, {
                                    key: anexo.id,
                                    class: "item-aula",
                                    clickable: "",
                                    tag: "a",
                                    href: `${_ctx.url}${anexo.anexo}`,
                                    target: "_blank"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item_section, { avatar: "" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_icon, {
                                            size: "17px",
                                            name: "attach_file",
                                            color: "primary"
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(anexo.descricao), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1032, ["href"]))
                                }), 128))
                              ], 64))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["caption", "label"]))
                  }), 128))
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_q_inner_loading, {
          key: 1,
          showing: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_spinner_facebook, {
              size: "30px",
              color: "primary",
              class: "q-ma-sm"
            }),
            _createTextVNode(" Carregando... ")
          ]),
          _: 1
        }, 8, ["showing"]))
  ], 64))
}